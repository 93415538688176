import { app, authConfig } from './firebaseDefaultConfig'

import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from "firebase/auth"

export default class FirebaseService {
  config = { ...authConfig }

  userPasswordLogin(...args) {
    const [data] = args
    const { email, password } = data

    return new Promise((resolve, reject) => {
      const auth = getAuth()

      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user
          const response = {
            data: {
              userData: {
                id: user.uid,
                fullName: user.displayName,
                username: user.displayName,
                avatar: user.photoURL ? user.photoURL : require('@src/assets/images/avatars/1-small.png').default,
                email: user.email,
                role: 'member',
                ability: [
                  {
                    action: 'manage',
                    subject: 'all'
                  }
                ],
                extras: {
                  eCommerceCartItemsCount: 5
                }
              },
              accessToken: user.accessToken
            }
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  googleSocialLogin() {
    return new Promise((resolve, reject) => {
      const provider = new GoogleAuthProvider()
      const auth = getAuth(app)

      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result)
          const token = credential.accessToken
          const user = result.user
          const response = {
            data: {
              userData: {
                id: user.uid,
                fullName: user.displayName,
                username: user.displayName,
                avatar: user.photoURL ? user.photoURL : require('@src/assets/images/avatars/1-small.png').default,
                email: user.email,
                role: 'member',
                ability: [
                  // {
                  //   action: 'read',
                  //   subject: 'ACL'
                  // },
                  // {
                  //   action: 'read',
                  //   subject: 'Auth'
                  // }, 
                  // {
                  //   action: 'read',
                  //   subject: 'Analytics'
                  // },
                  {
                    action: 'manage',
                    subject: 'all'
                  }
                ],
                extras: {
                  eCommerceCartItemsCount: 5
                }
              },
              accessToken: user.accessToken
            }
          }
          localStorage.setItem('userData', JSON.stringify(response))
          localStorage.setItem(this.config.storageTokenKeyName, JSON.stringify(token))
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  registerUserPassword(...args) {
    const [data] = args
    const { email, password, username } = data

    return new Promise((resolve, reject) => {
      const auth = getAuth()

      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user
          const response = {
            data: {
              user: {
                ...user,
                username,
                ability: [
                  {
                    action: 'manage',
                    subject: 'all'
                  }
                ]
              },
              accessToken: user.accessToken
            }
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
