import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFirestore } from "@firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyDp2fa4nU6UWa4jiUZcaun2bGhHk9esWt0",
  authDomain: "d4hub-84e1f.firebaseapp.com",
  projectId: "d4hub-84e1f",
  storageBucket: "d4hub-84e1f.appspot.com",
  messagingSenderId: "547238392377",
  appId: "1:547238392377:web:25981f07d577ffba08d3e8",
  measurementId: "G-YVPDM0LSTS"
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)

export const authConfig = {
    tokenType: 'Bearer',
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken'
}